import en from './assets/i18n/en.json'
import ind from './assets/i18n/in.json'
import VueI18n from 'vue-i18n'

import Vue from 'vue'

Vue.use(VueI18n);

export default new VueI18n({
    locale: localStorage.getItem('lang') || 'EN',
    fallbackLocale:'EN',
    messages: {
        EN : en,
        ID : ind,
    }
})