import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store/store'

import Home from '../views/Home/Home.vue'
import Logout from '../components/user/Logout/Logout.vue'




Vue.use(VueRouter, store)

  const routes = [
 
  {
    path: '/',
    name: 'Homepage',
    component: Home,
    meta: { requiresVisitor: true }
  },
  {
    path: '/home',
    name: 'Homepage',
    component: Home,
    meta: { requiresVisitor: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },

  {
    path:'/styleGuide',
    name:'StyleGuide',
    component: () => import(/* webpackChunkName: "StyleGuide" */ '../views/styleGuide/Index.vue'),
    meta: { requiresAuth: true}
  },

  
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/Home',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  }
  else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        path: '/styleGuide',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  }
  else {
    next() // make sure to always call next()!
  }
})

export default router
