import SignUp from "../SignUp/index.vue";
import CreatePassword from "../CreatePassword/index.vue";
import CountDown from "../../countdown/Index.vue";


export default {
  name: "Login",
  data() {
    return {
      signUpTitle:"",
      componentLoader: false,
      showSignUpComponent: false,
      valid: false,
      lazy: false,
      email:"",
      password:"",
      formRules : {
        required: true,
        validEmail : true
      },
      showCreatePasswordComp: false,
      createPasswordDetails : {},
      otpSent:false,
      otpTimeOutTime:null
    };
  },

  components: {
    SignUp,
    CreatePassword,
    CountDown
  },

  watch: {
    'phoneNumberVal' () {
      this.numberRules = []
    },

    '$store.state.language' () {
      if(!this.showSignUpComponent){
        this.$refs.form.validate()
      }
      
    },
  },

  methods: {
    login() {



      this.formRules = {
        required: value => !!value || this.$t('common.required'),
        validEmail : value => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) || this.$t("common.email_valid")
      }

      setTimeout(()=>{
        if (this.$refs.loginForm.validate()) {
          this.componentLoader = true;
          this.$store.dispatch("connectToApi", {
              url: "user/login",
              params: {
                email: this.email,
                password: this.password,
              },
              rule: "post",
              token: false,
              commonLoader: false,
            })
            .then((response) => {
              this.componentLoader = false;
              if (response.data.status_code == 200) {
                this.$store.dispatch('retrieveToken', {
                    token: response.data.data.token
                }).then(() => {
                    this.$router.push('/styleGuide')
                })

                this.$store.commit('storeUserDetails', response.data.data.user)

              } else {
                console.log(response.data);
              }
            }).catch((er) => {
              this.componentLoader = false;
              if(er.response.status == 409){
                this.resendOtp()
              }
              
            });
        }
      },100)
    },

    verifyOtp() {
      this.formRules.required = value => !!value || this.$t('common.required');

      setTimeout(() => {
        if (this.$refs.verifyOtpForm.validate()) {

            this.componentLoader = true;
            this.$store.dispatch("connectToApi", {
              url: "user/verify-email",
              params: {
                email: this.email,
                otp: this.validOtp
              },
              rule: "patch",
              token: false,
              commonLoader: false,
            })
              .then(() => {
                this.componentLoader = false;
                this.otpSent = false;
                this.showLoginComp();
              }).catch((er) => {
                this.componentLoader = false;
                console.log(er);
              });
         
         


        }
      }, 10)
    },

    resendOtp() {
      this.otpStatusMessage = null;
      this.componentLoader = true;
      this.otpTimeOutTime = 0;     

      this.$store.dispatch("connectToApi", {
        url: "user/resend-otp",
        params: {
          email: this.email,
        },
        rule: "post",
        token: false,
        commonLoader: false,
      })
        .then((response) => {
          this.componentLoader = false;
          this.otpStatusMessage = response.data.message;
          this.otpSent = true

          setTimeout(() => {
            this.otpTimeOutTime = Number(response.data.data.otp_expire_duration_in_seconds);
          });

        }).catch((er) => {
          this.componentLoader = false;
          console.log(er);
        });
    },

    goToSignUp(type) {
      this.showSignUpComponent = true;
      if(type == "new"){
        this.signUpTitle = "new"
      }
      else if (type ==  "forgot"){
        this.signUpTitle = "forgot"
      }
      
    },

    showLogin() {
      this.showSignUpComponent = false;
      setTimeout(() => {
        this.$refs.emailElm.focus();
      }, 2);
    },

    jumpToLogin() {
      this.showSignUpComponent = false;
      this.showCreatePasswordComp = false;
      setTimeout(() => {
        this.$refs.emailElm.focus();
      }, 500);
    },

    goToCreatePasswordComponent(val){
      this.createPasswordDetails = val;
      this.showCreatePasswordComp = true;
    }
  },

  mounted() {
    setTimeout(() => {
      this.$refs.emailElm.focus();
    }, 500);
  },


};
