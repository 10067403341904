<template>
  <v-app>
    <v-snackbar v-model="snackbar" :timeout="snakBarTimeOut" top color="red">
      <div v-for="message in errorMessages" :key="message.index">
        {{ message.message }}
      </div>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarSuccess"
      :timeout="snakBarTimeOut"
      top
      color="green"
    >
      <div class="text-center">{{ successMessage }}</div>
    </v-snackbar>

    <v-app-bar app color="white">
      <div class="d-flex align-center logo">
        <a @click="reload" v-if="!showUserDetails" key="loginLogo">
          <img src="@/assets/images/Logo.png" alt="" />
        </a>

        <router-link to="/styleGuide" v-if="showUserDetails" key="loggedInLogo">
          <!-- <img alt=""  :src="`data:image/png;base64,${loaderLogo}`"/> -->
          <img src="@/assets/images/Logo.png" alt="" />
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <div class="mr-4 navigations">
        <router-link to="/about"   class="mr-2">
          About Us
        </router-link>

        <router-link to="/styleGuide"  class="mr-2" v-if="showUserDetails">
          Style Guide
        </router-link>

        <router-link to="/home"  class="mr-2" v-if="!showUserDetails">
          Login
        </router-link>
      </div>
      <div class="userDetails" v-if="showUserDetails">
        <div>
          <div class="user">
            {{ userDetails && userDetails.first_name }}
          </div>

          <span>Role Name</span>
         
          | <router-link to="/Logout">Logout</router-link>
        </div>
        <v-icon large color="primary lighten-1" @click="openEditProfile">
          mdi-face
        </v-icon>
      </div>

      <div class="lang-switcher">
        <div class="chooseLang" v-if="$i18n.availableLocales.length <= 2">
          <span
            :class="{ active: lang === $i18n.locale }"
            v-for="lang in $i18n.availableLocales"
            :key="lang.index"
            @click="chooseLanguageClick(lang)"
          >
            {{ lang }}
          </span>
        </div>
        <v-select
          class="mt-5 language-slector"
          v-else
          :items="$i18n.availableLocales"
          light
          outlined
          dense
          v-model="$i18n.locale"
          @change="chooseLanguageClick"
        >
        </v-select>
      </div>
    </v-app-bar>

    <v-main>
     
      <router-view></router-view>
    </v-main>

    <div class="loading" v-if="isLoader">
      <div class="loader-wrapper">
        <img v-if="loaderLogo" :src="`data:image/png;base64,${loaderLogo}`" />
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>


    <v-dialog
      class="pa-0"
      v-model="profileModel"
      scrollable
      max-width="500"
      v-on:click:outside="onModelClose()"
    >
      <v-card>
        <a class="close" @click="onModelClose"></a>

        <v-card-title>Edit Profile</v-card-title>

        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form
            @submit.prevent="editProfile"
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
            class="login-form"
          >
            <div class="d-flex editable-row">
              <v-text-field
                id="txtFirstName"
                :disabled="firstNameDisabled"
                ref="firstField"
                class="w-100"
                v-bind:label="$t('common.firstName')"
                outlined
                type="text"
                :rules="[formRules.required]"
                v-model="firstName"
              >
              </v-text-field>
              <a class="edit" @click="firstNameDisabled = false"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>
            <div class="d-flex editable-row">
              <v-text-field
                class="w-100"
                :disabled="lastNameDisabled"
                v-bind:label="$t('common.lastName')"
                outlined
                type="text"
                :rules="[formRules.required]"
                v-model="lastName"
              >
              </v-text-field>
              <a class="edit" @click="lastNameDisabled = false"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>

            <div class="d-flex editable-row">
              <v-text-field
                outlined
                class="code fixed-width"
                type="number"
                v-model="countryCode"
              ></v-text-field>
              <v-text-field
                ref="phoneNumber"
                class="w-100 ml-5"
                v-bind:label="$t('common.phone_number')"
                outlined
                type="number"
                :rules="[formRules.required, formRules.tenNumbers]"
                v-model="phoneNumber"
              >
              </v-text-field>
              <a class="edit" @click="pnoneDisabled = false"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>
            <div class="d-flex editable-row">
              <v-text-field
                class="w-100"
                :disabled="emailDisabled"
                v-bind:label="$t('common.email')"
                outlined
                type="email"
                :rules="[formRules.email, formRules.required]"
                v-model="emailId"
              >
              </v-text-field>
              <a class="edit" @click="emailDisabled = false"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>

            <div class="d-flex editable-row">
              <v-text-field
                class="w-100"
                v-bind:label="$t('account.password')"
                outlined
                :rules="[formRules.required]"
                v-model="password"
                type="password"
              >
              </v-text-field>
              <a
                class="edit"
                v-if="!changePasswordSection"
                @click="showChangePassword"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </div>

            <div class="edit-password" v-if="changePasswordSection">
              <v-text-field
                class="w-100"
                v-bind:label="$t('account.newPassword')"
                outlined
                :rules="[formRules.required, formRules.password]"
                v-model="newPassword"
                type="password"
              >
              </v-text-field>
              <div class="d-flex">
                <v-text-field
                  class="w-100"
                  v-bind:label="$t('account.Reenter_password')"
                  outlined
                  :rules="[formRules.required, formRules.validPassword]"
                  v-model="password_confirmation"
                  type="password"
                >
                </v-text-field>

                <v-btn class="sign-btn ml-2" @click="hideChangePassword"
                  >X</v-btn
                >
              </div>
            </div>

            <v-btn
              class="sign-btn float-right"
              large
              color="primary"
              type="submit"
              v-if="!componentLoader"
            >
              {{ $t("common.update") }}
            </v-btn>

            <v-btn
              large
              color="primary"
              v-if="componentLoader"
              class="sign-btn float-right"
            >
              <v-progress-circular
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style lang="scss">
.editable-row {
  position: relative;

  .edit {
    position: absolute;
    right: 4px;
    top: 10px;
    width: 30px;
    height: 30px;
    background: #ddd;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.back-button {
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 0 5px -2px #000;
  border-radius: 5px;
  margin: 10px 0 -27px 40px;
  display: block;
  width: 40px;
  text-align: center;

  @media screen and (max-width: 767px) {
    margin: 10px 0 4px 5px;
  }
}

.loading {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);

  .loader-wrapper {
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    box-shadow: 0 0 30px -3px #000;
    position: relative;

    img {
      width: 80px;
    }

    .v-progress-circular {
      margin: 0 auto;
      flex-shrink: 0;
      position: absolute;
    }
  }
}

.chooseLang {
  span {
    padding: 0 5px;
    color: #333333;
    line-height: 0;
    cursor: pointer;

    &:first-child {
      border-right: 1px solid #666;
    }

    &.active,
    &:hover {
      color: #1976d2;
    }
  }
}

.logo {
  height: 60px;

  @media screen and (max-width: 500px) {
    height: 35px;
  }

  a {
    height: 90%;
  }

  img {
    height: 90%;
  }
}

.lang-switcher {
  flex-shrink: 0;
}

.chooseLang {
  background: #eee;
  border-radius: 5px;
  padding: 5px;
  width: 71px;
}
.language-slector {
  width: 75px;
  font-size: 12px;

  .v-select__selection {
    font-size: 12px;
  }
}
.loader {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 50px;
  font-weight: bold;
}

.userDetails {
  text-align: right;
  color: #333333;
  padding-right: 10px;
  line-height: 16px;
  display: flex;
  font-size: 14px;

  a {
    text-decoration: none;
  }
}

.navigations {
  a {
    text-decoration:none;
    background: #488fef;
    color:#fff;
    padding:5px;
    border-radius:3px;
    color:#fff !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      isLoader: this.$store.state.apiLoader,
      snackbar: false,
      snackbarSuccess: false,
      snakBarTimeOut: 5000,
      snakBarText: "",
      userDetails: null,
      showUserDetails: false,
      errorMessages: [],
      successMessage: "",
      showBakButton: false,
      loaderLogo: "",

      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailId: "",
      password: "",
      newPassword: "",
      password_confirmation: "",

      componentLoader: false,
      profileModel: false,

      valid: false,
      lazy: false,
      formRules: {
        required: true,
        tenNumbers: true,
        email: true,
        password: true,
        validPassword: true,
      },

      countryCode: "91",

      pnoneDisabled: true,
      lastNameDisabled: true,
      firstNameDisabled: true,
      emailDisabled: true,
      changePasswordSection: false,
    };
  },

  methods: {
    showChangePassword() {
      this.changePasswordSection = true;
      this.newPassword = "";
      this.password_confirmation = "";
    },

    hideChangePassword() {
      this.changePasswordSection = false;
      this.newPassword = "";
      this.password_confirmation = "";
    },
    editProfile() {
      this.formRules = {
        required: (value) => !!value || this.$t("common.required"),
        tenNumbers: (value) =>
          (value && value.length > 7 && value.length < 12) ||
          this.$t("common.valid_ten_dig_phone"),
        email: (value) =>
          !value ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          this.$t("common.Email_Id_Invalid"),
        password: (value) =>
          (!!value && value.length >= 6) ||
          this.$t("common.atLeastSixCharacters"),
        validPassword: (value) =>
          (!!value && value.length >= 6 && value == this.newPassword) ||
          this.$t("common.shouldBeSame"),
      };

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.componentLoader = true;
          let apiData = {
            first_name: this.firstName,
            last_name: this.lastName,
            country_code: this.countryCode,
            mobile_number: this.phoneNumber,
            old_password: this.password,
            password: this.newPassword,
            password_confirmation: this.password_confirmation,
          };

          this.componentLoader = true;
          this.$store
            .dispatch("connectToApi", {
              url: "user/update-profile",
              params: apiData,
              rule: "put",
              token: true,
              commonLoader: false,
            })
            .then(() => {
              this.profileModel = false;
              this.$refs.form.reset();
              this.componentLoader = false;
              this.getUsersDetais();
            })
            .catch(() => {
              this.componentLoader = false;
            });
        }
      }, 100);
    },

    onModelClose() {
      this.profileModel = false;
      this.firstName = "";
      this.lastName = "";
      this.phoneNumber = "";
      this.emailId = "";

      this.formRules = {
        required: true,
        tenNumbers: true,
        email: true,
        password: true,
        validPassword: true,
      };
    },

    openEditProfile() {
      this.pnoneDisabled = true;
      this.lastNameDisabled = true;
      this.firstNameDisabled = true;
      this.emailDisabled = true;
      let theUser = this.$store.state.userDetails;
      this.firstName = theUser && theUser.first_name && theUser.first_name;
      this.lastName = theUser && theUser.last_name && theUser.last_name;
      this.phoneNumber = theUser && theUser.mobile_number && theUser.mobile_number;

      this.emailId = theUser && theUser.email && theUser.email;
      this.profileModel = true;
    },

    chooseLanguageClick(val) {
      if (localStorage.getItem("lang") != val) {
        this.$store.commit("setLanguage", val);
        this.$i18n.locale = val;
        this.$i18n.locale = val;
        localStorage.setItem("lang", val);

        if (this.$store.getters.loggedIn) {
          var lang = val.toLowerCase();
          this.$store
            .dispatch("connectToApi", {
              url: "/users/change-language/" + lang,
              params: {},
              rule: "get",
              token: true,
              commonLoader: true,
            })
            .then(() => {
              //location.reload()
            })
            .catch((er) => {
              console.log(er);
            });
        } else {
          //location.reload()
        }
      }
    },

    setLanguage(val) {
      this.$i18n.locale = val;
      localStorage.setItem("lang", val);
    },

    getUsersDetais() {
      this.$store
        .dispatch("connectToApi", {
          url: "user/profile",
          params: {},
          rule: "get",
          token: true,
          commonLoader: true,
        })
        .then((response) => {
          this.userDetails = response.data.data.user;
          this.$store.commit("storeUserDetails", this.userDetails);
          localStorage.setItem("logoImage", this.loaderLogo);
          this.$store.commit("storeLogo", this.loaderLogo);
        })
        .catch((er) => {
          console.log(er);
        });
    },

    reload() {
      location.reload();
    },

    goToBack() {
      this.$router.go(-1);
    },
  },

  mounted() {
    if (this.$store.getters.loggedIn) {
      this.getUsersDetais();
      if (this.$route.name !== "Dashboard") {
        this.$nextTick(() => {
          this.showBakButton = true;
        });
      } else {
        this.showBakButton = false;
      }
    }
    if (localStorage.getItem("logoImage")) {
      this.loaderLogo = localStorage.getItem("logoImage");
    }
  },

  watch: {
    "$store.state.apiLoader": function() {
      this.isLoader = this.$store.state.apiLoader;
    },

    "$store.state.apiSuccessMessage": function() {
      this.snackbar = false;

      if (this.$store.state.apiSuccessMessage) {
        this.successMessage = this.$store.state.apiSuccessMessage;
        this.snackbarSuccess = true;

        setTimeout(() => {
          this.$store.commit("getSuccessMessage", null);
          this.snackbarSuccess = false;
          this.successMessage = "";
        }, this.snakBarTimeOut);
      }
    },

    "$store.state.apiErrorMessage": function() {
      this.snackbar = false;

      if (this.$store.state.apiErrorMessage) {
        this.snakBarText = this.$store.state.apiErrorMessage;

        if (typeof this.snakBarText === "object") {
          for (const property in this.snakBarText) {
            this.errorMessages.push({
              message: `${this.snakBarText[property]}`,
            });
          }
        } else {
          this.errorMessages.push({ message: this.snakBarText });
        }

        this.errorMessages.push();

        this.snackbar = true;

        setTimeout(() => {
          this.$store.commit("getApiErrorMessage", null);
          this.snackbar = false;
          this.errorMessages = [];
        }, this.snakBarTimeOut);
      }
    },

    "$store.state.userDetails": function() {
      
      if (
        this.$store.state.userDetails ) {
        this.userDetails = this.$store.state.userDetails;
        this.showUserDetails = true;
        let lng;
        if (this.userDetails.lang == "en") {
          lng = "EN";
        } else if (this.userDetails.lang == "id") {
          lng = "ID";
        }
        this.setLanguage(lng);
      } else {
        this.showUserDetails = false;
      }
    },

    "$store.state.logo": function() {
      if (this.$store.state.logo) {
        this.loaderLogo = this.$store.state.logo;
      }
    },

    $route(to) {
      if (to.name != "Dashboard") {
        this.$nextTick(() => {
          this.showBakButton = true;
        });
      } else {
        this.showBakButton = false;
      }
    },
  },
};
</script>
