import moment from 'moment-timezone'

export default {
    name: 'CountDown',

    props: {
        count : {
            type:Number
        }
    },
    data() {
        return {
            countMinutes:0,
            otpExpired: false,
            isMinute: true,
            isSecond:false,
        }
    },

    components: {
       
    },


    methods: {
        countDownSecond(){
            this.isMinute = false;
            this.isSecond = true;
            this.countMinutes = 60;
            let t;

            t = setInterval(()=>{
                if(this.countMinutes >= 1){
                    this.countMinutes = this.countMinutes - 1
                }
                else{
                    this.otpExpired = true;
                    clearInterval(t)
                }
                
            },1000);
        }
    },


    mounted() {

    },


    watch: {
        "count": function() {
            let t;
            clearInterval(t)
            this.otpExpired = false;
            this.isMinute = true;
            this.isSecond = false;

            this.countMinutes = moment.utc(this.count*1000).format('mm:ss')

            if(this.count > 0){
                
                let getCount = this.count;

                 t = setInterval(()=>{
                    getCount = getCount - 1;

                    if(getCount == 0){
                        this.otpExpired = true;
                         clearInterval(t)
                    }else{
                        this.countMinutes = moment.utc(getCount*1000).format('mm:ss')
                    }

                    
                },1000);
                
            }
        },
    }


}