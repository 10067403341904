
export default {
    name: 'Logout',
    data() {
        return {

        }
    },

    components: {
       
    },

    methods: {
    },


    mounted() {
        this.$store.dispatch("hideAPiLoader");
        this.$store.dispatch('destroyToken')
            .then(() => {
                this.$store.commit('storeUserDetails', null)
                this.$router.push('/home')
            })
    }
}